<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>登記預約中心</b-breadcrumb-item>
            <b-breadcrumb-item active>預約日曆</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card title="預約日曆">
      <b-overlay :show="showLoading" rounded="sm">
        <b-row no-gutters>
          <b-col sm="4" lg="3">
            <b-form-select v-model="bookingPresetId" :options="bookingPresetsOptions" class="mr-auto mt-2 mb-4" />
          </b-col>
        </b-row>
        <FullCalendar :options="calendarOptions">
          <template v-slot:eventContent="arg">
            <span>{{ arg.event.extendedProps.eventContent }}</span>
            <div :id="`tooltip_${arg.event.extendedProps.id}`" class="floating-tooltip" role="tooltip">
              <div class="popover" :class="`bs-popover-${placement}`">
                <h3 class="popover-header">{{ arg.event.extendedProps.eventContent }}</h3>
                <div class="popover-body">
                  <div v-for="field in bookingPresetFields" :key="field.key">
                    <span>{{ field.label }}</span>
                    <span>：</span>
                    <template v-if="arg.event.extendedProps">

                      <span>{{
                        isObject(arg.event.extendedProps[field.key])
                          ? arg.event.extendedProps[field.key].text :
                          arg.event.extendedProps[field.key]
                      }}</span>
                    </template>
                  </div>
                  <router-link :to="{
                    name: 'BookingCenterBookingEdit',
                    params: {
                      id: bookingPresetId,
                      booking_id: arg.event.extendedProps.id
                    }
                  }"><span @click="() => closeTooltip()">查看明細</span></router-link>
                </div>
                <span :id="`arrow_${arg.event.extendedProps.id}`" class="arrow"></span>
              </div>
            </div>
          </template>
        </FullCalendar>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import bookingApi from '@/apis/booking'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import zhTwLocale from '@fullcalendar/core/locales/zh-tw'
import collectionApi from "@/apis/collection"
import { arrow, autoPlacement, computePosition, flip, offset, shift } from '@floating-ui/dom'

function clickOutside(element, callback) {
  function handleClick(event) {
    if (!element.contains(event.target)) {
      callback(event)
      document.removeEventListener('click', handleClick)
    }
  }
  document.addEventListener('click', handleClick)
}

export default {
  components: {
    FullCalendar
  },
  data() {
    return {
      showLoading: false,
      bookingPresets: [],
      collections: [],
      bookings: null,
      bookingPresetFields: [],
      placement: 'top',
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin
        ],
        locales: [zhTwLocale],
        locale: 'zh-tw',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        firstDay: 1,
        initialView: 'dayGridMonth',
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        events: [],
        eventClick: (arg) => {
          const button = arg.el
          const tooltip = document.querySelector(`#tooltip_${arg.event.extendedProps.id}`)
          this.currentTooltip = tooltip
          const arrowEl = document.querySelector(`#arrow_${arg.event.extendedProps.id}`)

          tooltip.style.display = 'block'
          document.body.appendChild(tooltip)

          computePosition(button, tooltip, {
            placement: this.placement,
            middleware: [
              offset(6),
              flip(),
              shift(),
              autoPlacement({
                alignment: 'start',
                allowedPlacements: ['top', 'bottom'],
              }),
              arrow({ element: arrowEl, padding: 8 })],
            strategy: 'fixed',
          }).then(({ x, y, middlewareData }) => {
            Object.assign(tooltip.style, { left: `${x}px`, top: `${y}px` })

            const { x: arrowX, y: arrowY } = middlewareData.arrow
            Object.assign(arrowEl.style, {
              left: arrowX != null ? `${arrowX}px` : '',
              top: arrowY != null ? `${arrowY}px` : '',
              right: '',
              bottom: '',
            })
            setTimeout(() => {
              clickOutside(tooltip, () => {
                tooltip.style.display = 'none'
              })
            })
          })
        },
      },
      currentTooltip: null,
    }
  },
  computed: {
    bookingPresetId: {
      get() {
        return this.$route.query.bookingPresetId ?? null
      },
      set(bookingPresetId) {
        this.$router.replace({
          query: {
            bookingPresetId: bookingPresetId ?? undefined
          }
        })
      }
    },
    currentBookingPreset() {
      return this.bookingPresets.find(booking => booking.id === this.bookingPresetId)
    },
    bookingPresetsOptions() {
      return [{ text: '請選擇', value: null, }, ...this.bookingPresets.map(booking => ({
        text: booking.name,
        value: booking.id,
      }))]
    },
  },
  mounted() {
    this.fetchBookingPresets()
  },
  watch: {
    bookingPresetId: {
      handler(id) {
        if (!id) return
        this.fetchBookings(id)
      },
      immediate: true
    },
    currentBookingPreset: {
      async handler(bookingPreset) {
        if (!bookingPreset) return
        this.bookingPresetFields = await this.fetchBookingPresetFields(bookingPreset)
        this.bookings = this.calculateBookings(this.bookings)
        this.updateCalenderOptions()
      },
      immediate: true
    },
  },
  methods: {
    async fetchBookingPresets() {
      try {
        this.showLoading = true
        const response = await bookingApi.getBookingPresets({
          page: 1,
          per_page: 20,
          is_enabled: true
        });
        this.bookingPresets = response.data.data
        this.bookingPresetId = this.bookingPresetId ?? this.bookingPresets[0]?.id
      } catch (error) {
        console.error(error)
        this.$swal("錯誤", "讀取資料集項目錯誤", "error")
      } finally {
        this.showLoading = false
      }
    },
    async fetchBookings(presetId) {
      try {
        this.showLoading = true
        const response = await bookingApi.getBookings(presetId, {
          start_at: null,
          end_at: null,
          keyword: '',
        })
        this.bookings = this.calculateBookings(response.data.data)
        this.updateCalenderOptions()
      }
      catch (error) {
        console.error(error)
        this.$swal("錯誤", "讀取資料集項目錯誤", "error")
      } finally {
        this.showLoading = false
      }
    },
    updateCalenderOptions() {
      this.calendarOptions.events = this.bookings.map(booking => {
        return {
          id: booking.id,
          title: booking.data[0]?.value,
          start: booking.start_at,
          end: booking.end_at,
          extendedProps: { ...booking, eventContent: this.calcEventContent(booking) }
        }
      })
    },
    calcEventContent(booking) {
      const titleId = this.currentBookingPreset?.config?.booking_mapping?.bookings?.title
      const title = booking?.data?.find(field => field.field_id === titleId)?.value
      const customerNameOrId = booking?.customer?.name || booking?.customer_id
      return title || customerNameOrId || booking.id
    },
    closeTooltip() {
      if (this.currentTooltip) {
        this.currentTooltip.style.display = 'none'
      }
    },
    async fetchBookingPresetFields(bookingPreset) {
      let newField = []

      for (let i = 0; i < bookingPreset?.config?.fields.length; i++) {
        const field = bookingPreset?.config?.fields?.[i]

        if (field.config?.list_hidden === true) {
          continue
        }

        let fieldConfig = {
          key: field._id ?? field.config._id,
          label: field.config.title,
          sortable: true,
        }

        if (field.type === "collection_select" && !this.collections.find((collection) => collection.id === field.config.collection_id)) {
          let collectionResponse = await collectionApi.getCollection(field.config.collection_id);
          this.collections.push(collectionResponse.data.data);
        }

        newField.push(fieldConfig)
      }
      return newField
    },
    calculateBookings(bookings) {
      return bookings.map(booking => {
        let newBooking = { ...booking }
        for (let i = 0; i < booking.data.length; i++) {
          let data = booking.data[i]
          if (Array.isArray(data.value)) {
            newBooking[data.field_id] = data.value.join(', ')
            continue
          }
          if (typeof data.value === 'object' && data.value !== null && data.value.type === 'collection_select') {
            const collection = this.collections.find((collection) => collection.id === data.value.collection_id);

            if (!collection) {
              continue
            }

            const collectionItem = collection.items.find((item) => item.id === data.value.collection_item_id)

            const mainDisplayField = collection.config.fields.find((field) => field.main_display)
            if (!mainDisplayField) {
              data.value = {
                ...data.value,
                text: collectionItem.data[0].value,
              }
            } else {
              let mainDisplayData = collectionItem.data.find((data) => data.field === mainDisplayField.id).value
              if (mainDisplayData) {
                data.value = {
                  ...data.value,
                  text: mainDisplayData,
                }
              }
            }
          }
          newBooking[data.field_id] = data.value
        }

        return newBooking
      })
    },
    isObject(field) {
      return typeof field === 'object' && field !== null;
    },
  }
}
</script>

<style scoped lang="scss">
.floating-tooltip {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: max-content;
  padding: 5px;
  font-weight: bold;
  border-radius: 4px;
  font-size: 90%;
  z-index: 10000;

  .popover {
    position: relative;
  }
}
</style>
